

<template>
  <b-modal id="clerk-create-modal" title="Clerk Create Modal" hide-footer scrollable size="lg"
    @hidden="resetModal" :no-close-on-esc="true" :no-close-on-backdrop="true" centered>
    <template #modal-title>
      <h2 class="m-0">Create Mess Receptionist</h2>
    </template>
    <template v-if="isBusy">
      <div class="text-center text-danger my-2">
        <b-spinner :variant="'primary'" class="align-middle"></b-spinner>
      </div>
    </template>
    <template v-else>
      <validation-observer ref="clerkCreateFormValidation">
        <b-form @submit.prevent>
          <!-- Clerk Dropdown -->
          <b-row>
            <b-col>
              <validation-provider #default="{ errors }" name="Clerk" rules="required">
                <b-form-group label-for="clerk" :state="errors.length > 0 ? false : null">
                  <template #label>
                    Mess Receptionist Details <span class="text-danger">*</span>
                  </template>
                  <b-row>
                    <b-col>
                      <v-select id="clerk" inputId="id" label="name" v-model="selectedClerk"
                        :options="clerks" placeholder="Mess Receptionist" class="v-style-chooser" />
                    </b-col>
                  </b-row>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <!-- mess Dropdown -->
          <b-row>
            <b-col>
              <validation-provider #default="{ errors }" name="Messes" rules="required">
                <b-form-group label-for="messes" :state="errors.length > 0 ? false : null">
                  <template #label>
                    Mess Details <span class="text-danger">*</span>
                  </template>
                  <b-row>
                    <b-col>
                      <v-select id="messes" inputId="id" label="name" v-model="selectedMess"
                        :options="messes" placeholder="Mess" class="v-style-chooser" :disabled="messDisabled"/>
                    </b-col>
                  </b-row>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Other form fields -->

          <b-form-group class="text-right">
            <b-button type="submit" variant="primary" pill class="mr-1 mt-2" @click="validationForm">
              Create
            </b-button>
          </b-form-group>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import util from "@/util.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VuePerfectScrollbar,
    draggable,
  },
  mixins: [util],
  props: ["showModal", "data"],
  data() {
    return {
       perfectScrollbarSettings: {
          maxScrollbarLength: 150,
          wheelPropagation: false,
          useBothWheelAxes: false,
          suppressScrollX: true,
        },

      selectedClerk: null,
      clerks: [],
      selectedMess: null,
      messes: [],
      required,
      isBusy: true,
      messDisabled: false,
    };
  },
  async mounted() {
    if (this.showModal) {
      try {

        this.isBusy = true
        
        let res = await this.getMessesUnpaginated({})
        this.messes = res.data

        res = await this.getUsersUnPaginated({
          is_officer: false,
          no_mess_secretaries: true,
          no_clerks: true,
          only_booking_users: true
        })

        this.clerks = res.data

        if(this.getLoggedInUser.mess_secretary){
          for(let i=0; i<this.messes.length; i++){
            if(this.messes[i].id == this.getLoggedInUser.mess_secretary.mess){
              this.selectedMess = this.messes[i]
              break;
            }
          }
          this.messDisabled=true
        }
        this.isBusy = false

      } catch (error) {
        this.displayError(error);
      }
    }
  },
  methods: {
     ...mapActions({
        createClerk: "appData/createClerk",
        getMessesUnpaginated: "appData/getMessesUnpaginated",
        getUsersUnPaginated: "appData/getUsersUnPaginated",
      }),
    async validationForm() {
      const success = await this.$refs.clerkCreateFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async submit() {
      try {
        let formData = new FormData();

        let dataToInsert = {
          user: this.selectedClerk.id,
          mess: this.selectedMess.id,
          created_by: this.getLoggedInUser.id,
          updated_by: this.getLoggedInUser.id,
        };
        formData.append("data", JSON.stringify(dataToInsert));
        
        const res = await this.createClerk(dataToInsert);
        if (res.status === 201) {
          this.$swal({
            title: "Mess Receptionist created successfully",
            icon: "success",
          });
          this.$nextTick(() => {
            this.$bvModal.hide("clerk-create-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
      reset() { },
      resetModal() {
      },
  },
  computed: {
      ...mapGetters({ getLoggedInUser: "appData/getUser" }),
    },
    watch: {
    },  
};
</script>

<style lang="scss" scoped>
</style>
