<template>
  <b-modal id="clerk-edit-modal" title="Clerk Edit Modal" hide-footer scrollable size="lg"
    @hidden="resetModal" :no-close-on-esc="true" :no-close-on-backdrop="true" centered>
    <template #modal-title>
      <h2 v-if="allowEdit" class="m-0">Edit Mess Receptionist</h2>
      <h2 v-else class="m-0">View Mess Receptionist</h2>
    </template>
    <template v-if="isBusy">
      <div class="text-center text-danger my-2">
        <b-spinner :variant="'primary'" class="align-middle"></b-spinner>
      </div>
    </template>
    <template v-else>
      <validation-observer ref="clerkEditFormValidation">
        <b-form @submit.prevent>
          <!-- Clerk Dropdown -->
          <b-row>
            <b-col>
                <validation-provider #default="{ errors }" name="Clerk" rules="required">
                <b-form-group label-for="clerk" :state="errors.length > 0 ? false : null">
                    <template #label>
                      Mess Receptionist <span class="text-danger">*</span>
                    </template>
                    <b-row>
                        <b-col>
                            <v-select id="clerk" inputId="id" label="name" v-model="selectedClerk"
                            :options="clerks" placeholder="Mess Receptionist" class="v-style-chooser" :disabled="!allowEdit"/>
                        </b-col>
                    </b-row>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
                </validation-provider>
              </b-col>
          </b-row>
          <!-- mess Dropdown -->
              <b-row>
            <b-col>
              <validation-provider #default="{ errors }" name="Messes" rules="required">
                <b-form-group label-for="messes" :state="errors.length > 0 ? false : null">
                  <template #label>
                    Mess Details <span class="text-danger">*</span>
                  </template>
                  <b-row>
                    <b-col>
                      <v-select id="messes" inputId="id" label="name" v-model="selectedMess"
                        :options="messes" placeholder="Mess" class="v-style-chooser" :disabled="!allowEdit || messDisabled"/>
                    </b-col>
                  </b-row>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <!-- Other form fields -->

          <template v-if="allowEdit">
              <b-form-group class="text-right">
                  <b-button type="submit" variant="primary" pill class="mr-1 mt-2" @click="validationForm">
                  Update
                  </b-button>
              </b-form-group>
            </template>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import util from "@/util.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VuePerfectScrollbar,
    draggable,
  },
  mixins: [util],
  props: ["showModal", "clerk", "allowEdit"],
  data() {
    return {
       perfectScrollbarSettings: {
          maxScrollbarLength: 150,
          wheelPropagation: false,
          useBothWheelAxes: false,
          suppressScrollX: true,
        },

      selectedClerk: null,
      clerks: [],
      selectedMess: null,
      messes: [],
      required,
      isBusy: true,
      messDisabled: false,
    };
  },
  async mounted() {
    if (this.showModal) {

      try {

        this.isBusy = true

        let res = await this.getMessesUnpaginated({})
        this.messes = res.data

        res = await this.getUsersUnPaginated({
          is_officer: false,
          no_mess_secretaries: true,          
          no_clerks_except_current: this.clerk.user,
          only_booking_users: true
        })
        this.clerks = res.data

        for(let i=0; i<this.messes.length; i++){
          if(this.clerk.mess == this.messes[i].id){
            this.selectedMess = this.messes[i]
            break
          }
        }

        for(let i=0; i<this.clerks.length; i++){
          if(this.clerk.user == this.clerks[i].id){
            this.selectedClerk = this.clerks[i]
            break
          }
        }

        if(this.getLoggedInUser.mess_secretary){
          this.messDisabled=true
        }

        this.isBusy = false

      } catch (error) {
        this.displayError(error);
      }
    }
  },
  methods: {
    ...mapActions({
        updateClerk: "appData/updateClerk",
        getMessesUnpaginated: "appData/getMessesUnpaginated",
        getUsersUnPaginated: "appData/getUsersUnPaginated",
      }),
    async validationForm() {
      const success = await this.$refs.clerkEditFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async submit() {
      try {
        let formData = new FormData();

        let dataToInsert = {
          user: this.selectedClerk.id,
          mess: this.selectedMess.id,
          created_by: this.getLoggedInUser.id,
          updated_by: this.getLoggedInUser.id,
        };
        formData.append("data", JSON.stringify(dataToInsert));
        
        const res = await this.updateClerk({
          payload: dataToInsert,
          pk: this.clerk.id,
        });
        if (res.status === 200) {
          this.$swal({
            title: "Mess Receptionist updated successfully",
            icon: "success",
          });
          this.$nextTick(() => {
            this.$bvModal.hide("clerk-edit-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() { },
    resetModal() {
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getUser" }),
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
</style>
